import React from 'react';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyPaymentSteps';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202402/JulyAppDownload';
import { PAIDY_HOST, SITE_METADATA } from 'src/constants';
import { StickyNav } from 'src/components';
import { Box } from '@material-ui/core';
import Condition202402 from 'src/components/Merchant/Campaign/GeneralCashback202402/Condition202402';
import SectionNotes202402 from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionNotes202402';
import CashbackBanner202402 from 'src/components/Merchant/Campaign/GeneralCashback202402/CashbackBanner202402';
import PaidyBenefits202305 from 'src/components/Merchant/Campaign/GeneralCashback202305/PaidyBenefits202305';
import LinearCTAButton from 'src/components/LinearCTAButton';

const SEOProps = {
  title: SITE_METADATA.general_cashback_202402.title,
  description: SITE_METADATA.general_cashback_202402.description,
};
const NAVS = [
  'キャンペーン概要',
  'ペイディについて',
  'ペイディが選ばれる理由',
  'ご注意事項',
];

export default function GeneralCashback202402() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter backgroundColor="white">
      <Box display="flex" justifyContent="center" pt={4}>
        <LinearCTAButton text="使えるお店を探す" to={`${PAIDY_HOST}/shop/`} />
      </Box>
      <Box mt={4} />
      <StickyNav navs={NAVS} />
      <Condition202402 id={NAVS[0]} />
      <JulyPayLater
        id={NAVS[1]}
        description="AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。"
      />
      <JulyPaymentSteps />
      <CashbackBanner202402 />
      <PaidyBenefits202305 id={NAVS[2]} title="ペイディが選ばれる理由" />
      <SectionNotes202402 id={NAVS[3]} />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
