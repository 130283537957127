import React from 'react';
import { Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import ImgBanner1 from 'src/images/merchant/campaign/general_cashback_202402/cashback-banner-1.png';
import styles from './CashbackBanner202402.module.scss';

const BANNERS = [
  {
    alt: '1',
    src: ImgBanner1,
    href: 'https://www.amazon.co.jp/b?ie=UTF8&node=8215625051',
  },
];

export default function CashbackBanner202402() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <h1 className={styles.title}>
          同時開催中のキャンペーンも
          <br />
          お見逃しなく！
        </h1>
        <div className={styles.banners}>
          {BANNERS.map((banner) => (
            <OutboundLink href={banner.href}>
              <img
                alt={banner.alt}
                src={banner.src}
                width={311}
                height={336}
                className={styles.banner}
              />
            </OutboundLink>
          ))}
        </div>
      </Container>
    </section>
  );
}
